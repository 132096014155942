import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
});

function Integrations(props) {
    const classes = useStyles();

    return (
        <h1>Integration settings</h1>
    );
}

export default Integrations