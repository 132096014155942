import {Component} from 'react'

class Home extends Component {

    render() {
        return (
            <div>
                <p>select a boards in the menu on the left</p>
            </div>
        );
    }
}

export default Home