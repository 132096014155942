import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
});

function Permissions(props) {
    const classes = useStyles();

    return (
        <h1>Permission settings</h1>
    );
}

export default Permissions